
function Groups(props) {

    if(!props.show) return null;

    return (
        <div className="wrap horizontalcenter" style={{marginBottom: 0, marginTop: 0}}>
            {
                props.groups?.map(g => {
                    return (
                        <div onClick={() => props.setSelectedGroupId(g.groupId)} className="group">
                            <img style={{height: 100, width: 100}} className="groupImage" src={g.image} alt={g.groupName}/>
                        
                            <p className="h5">{g.groupName}</p>
                        </div>
                    )
                })
            }
        </div>
      );
}

export default Groups;