// 0 = Français, 1 = English
const lexique = {
    yes: ["Oui", "Yes"],
    no: ["Non", "No"],
    cancel: ["Annuler", "Cancel"],
    password: ["Mot de passe", "Password"],
    connexion: ["Connexion", "Connexion"],
    mailAddress: ["Adresse e-mail", "E-mail address"],
    noAccount: ["Je n'ai pas encore de compte", "I don't have an account yet"],
    forgotPassword: ["J'ai oublié mon mot de passe", "I forgot my password"],
    invalidIdentification: ["Erreur, compte invalid, veuillez contacter le support", "Error, invalid account, please contact support"],
    monday: ["Lundi", "Monday"],
    tuesday: ["Mardi", "Tuesday"],
    wednesday: ["Mercredi", "Wednesday"],
    thursday: ["Jeudi", "Thursday"],
    friday: ["Vendredi", "Friday"],
    saturday: ["Samedi", "Saturday"],
    sunday: ["Dimanche", "Sunday"],
    monday3: ["Lun", "Mon"],
    tuesday3: ["Mar", "Tue"],
    wednesday3: ["Mer", "Wed"],
    thursday3: ["Jeu", "Thu"],
    friday3: ["Ven", "Fri"],
    saturday3: ["Sam", "Sat"],
    sunday3: ["Dim", "Sun"],
    today: ["Aujourd'hui", "Today"],
    yesterday: ["Hier", "Yesterday"],
    january: ["Janvier", "January"],
    february: ["Fevrier", "February"],
    march: ["Mars", "March"],
    april: ["Avril", "April"],
    may: ["Mai", "May"],
    june: ["Juin", "June"],
    july: ["Juillet", "July"],
    august: ["Août", "August"],
    september: ["Septembre", "September"],
    october: ["Octobre", "October"],
    november: ["Novembre", "November"],
    december: ["Decembre", "December"],
    january3: ["Jan", "Jan"],
    february3: ["Fev", "Feb"],
    march3: ["Mar", "Mar"],
    april3: ["Avr", "Apr"],
    may3: ["Mai", "May"],
    june3: ["Jui", "Jun"],
    july3: ["Jui", "Jul"],
    august3: ["Aoû", "Aug"],
    september3: ["Sep", "Sep"],
    october3: ["Oct", "Oct"],
    november3: ["Nov", "Nov"],
    december3: ["Dec", "Dec"],
    calendar: ["Calendrier", "Calendar"],
    home: ["Accueil", "Home"],
    search: ["Rechercher", "Search"],
    searchRole: ["Rechercher un rôle", "Search for a role"],
    searchGroup: ["Rechercher un groupe", "Search for a group"],
    searchUser: ["Rechercher un utilisateur", "Search for a user"],
    newAppointment: ["Nouveau rendez-vous", "New Appointment"],
    newPredefinedAppointment: ["Nouveau rendez-vous prédéfinis", "New predefined Appointment"],
    nextAppointment: ["Prochains rendez-vous", "Next appointments"],
    newGroup: ["Nouveau groupe", "New group"],
    groupName: ["Nom du groupe", "Group name"],
    languages: ["Langues", "Languages"],
    languagesDescription: ["Choisissez votre language préféré !", "Choose your preferred language !"],
    settings: ["Réglages", "Settings"],
    settingsDescription: ["Pour parametrer votre profil", "To set up your profile"],
    profil: ["Profil", "Profil"],
    profilDescription: ["Pour parametrer votre profil", "To set up your profile"],
    changePassword: ["CHANGER DE MOT DE PASSE", "CHANGE PASSWORD"],
    characters: ["caractères", "characters"],
    specialCharacter: ["caractère spécial", "special character"],
    number: ["chiffre", "number"],
    uppercase: ["majuscule", "uppercase"],
    verification: ["Vérification", "Verification"],
    new: ["Nouveau", "New"],
    newPassword: ["Nouveau mot de passe", "New password"],
    actual: ["Actuel", "Actual"],
    actualPassword: ["Mot de passe actuel", "Actual password"],
    deleteAccount: ["Supprimer mon compte", "Delete my account"],
    deleteAccountDescription: ["Êtes vous sûr de vouloir supprimer votre compte ? 🥺", "Are you sure you want to delete your account? 🥺"],
    logout: ["Se déconnecter", "Logout"],
    registredSince: ["Inscrit depuis le", "Registred since"],
    about: ["À propos", "About"],
    introduction: ["introduction", "Introduction"],
    blockedUsers: ["Utilisateurs bloqués", "Blocked users"],
    send: ["Envoyer", "Send"],
    tellUsDescritpion: ["Dis nous ce qui te plait dans l'application !", "Tell us what you like about the application !"],
    privateMode: ["Mode privé", "Private mode"],
    longDistance: ["Grand déplacement", "Long distance trip"],
    shortDistance: ["Petit déplacement", "Short distance trip"],
    simpleTrip: ["Simple trajet", "Simple trip"],
    displacement: ["Déplacement", "Displacement"],
    myProfil: ["Mon profil", "My profil"],
    administration: ["Administration", "Administration"],
    myProfilDescription: ["Votre propre profil", "Your own profil"],
    administrationDescription: ["Gérez vos equipes et leurs déclarations directement ici", "Manage your teams and time statements directly here"],
    myGroups: ["Mes groupes", "My groups"],
    myLifePartners: ["Mes partenaires de vie", "My life partners"],
    myLifePartnersTeaser: ["Vous pourrez bientot ajouter vos enfants ou animaux de compagnie pour organiser leurs rendez-vous. Une nouvelle façon de prendre soin de ceux qui comptent le plus pour vous. 😁", "You'll soon be able to add your children or pets to organize their appointments. A new way to take care of those who mean the most to you. 😁"],
    myLifePartnersDescritpion: ["Tous vos partenaires de vie pour qui vous pouvez gérer leurs rendez-vous sans qu'ils ne puissent se créer eux-mêmes un compte (Exemples : Vos animaux de compagnie, vos enfants)", "All your life partners for whom you can manage their appointments without them being able to create an account themselves (Examples: Your pets, your children)."],
    myAvailabilities: ["Mes disponibilités", "My availabilities"],
    shareMyPlanning: ["Partager mon planning", "Share my planning"],
    reminders: ["Rappels", "Reminders"],
    filters: ["Filtres", "Filters"],
    now: ["Maintenant", "Now"],
    tomorrow: ["Demain", "Tomorrow"],
    next: ["prochain", "Next"],
    previous: ["dernier", "previous"],
    title: ["Titre", "Title"],
    description: ["Description", "Description"],
    pseudo: ["Pseudo", "Pseudo"],
    prerequisites: ["Prérequis", "Prerequisites"],
    address: ["Adresse", "Address"],
    group: ["Groupe", "Group"],
    groupSelection: ["Selection du groupe", "Group selection"],
    selectGroup: ["Selectionnez du groupe", "select group"],
    none: ["Aucun", "None"],
    ok: ["Ok", "Ok"],
    schedule: ["Horaires", "Schedule"],
    startingDate: ["Date début", "Starting date"],
    startingHour: ["Heure début", "Starting hour"],
    duration: ["Durée", "Duration"],
    repetition: ["Répetition", "Repetition"],
    conditions: ["Conditions", "Conditions"],
    noCondition: ["Auncune condition", "No condition"],
    addCondition: ["Ajouter une condition", "Add condition"],
    newCondition: ["Nouvelle condition", "New condition"],
    participants: ["Participants", "Participants"],
    members: ["Membres", "Members"],
    newMember: ["Nouveau membres", "New members"],
    seeAllMembers: ["Voir tous les membres", "See all members"],
    pendingAcceptance: ["Attente d'acceptation", "Pending acceptance"],
    create: ["Créer", "Create"],
    createReminder: ["Créer un rappel", "Create reminder"],
    createGroup: ["Créer un group", "Create group"],
    createAppointment: ["Créer un rendez-vous", "Create appointment"],
    canCreateAppointment: ["Peut créer un rendez-vous", "Can create appointment"],
    andAddPeopleToGroup : [" et ajouter des personnes au groupe", " and add people to group"],
    canOnlyApplyAppointment : ["Peut seulement participer aux rendez-vous", "Can only see and apply to appointments"],
    createAccount : ["Créer mon compte", "Create my account"],
    visibility : ["Visibilité", "Visibility"],
    public : ["Public", "Public"],
    publicDescription : ["En mode public, tout le monde pourra intégrer votre groupe et participer aux rendez-vous", "In public mode, everyone will be able to join your group and participate in meetings"],
    private : ["Privé", "Private"],
    privateDescription : ["En privé, seul les personnes du groupe pourront le voir et seul les membres autorisés pourront inviter d'autres utilisateurs à le rejoindre", "In private, only people in the group will be able to see it and only authorized members will be able to invite other users to join it"],
    onDemand : ["Sur demande", "On request"],
    onDemandDescription : ["Sur demande, tous les utilisateurs pouront voir votre groupe en le recherchant mais ils devront vous demander avant de pouvoir intégrer celui-ci et participer aux rendez-vous", "On request, every users will be able to see your group by searching for it but they will have to ask you before they can join it and participate in meetings"],
    coordinate : ["Coordonnée", "Coordinate"],
    noCoordinate : ["Aucune coordonnée", "No coordinate"],
    newCoordinate : ["Nouvelle coordonnée", "New coordinate"],
    youCanStillContactGroup : ["Vous pouvez toujours contacter ce groupe via sa messagerie ou en contactant un administrateur", "You can still contact this group via messaging or by contacting an administrator"],
    roleAvailables : ["Role disponibles", "Role availables"],
    missingInformation : ["Informations manquantes", "Missing information"],
    missingInformationDescription : ["Vous devez renseigner au moins une valeur et une description", "You must enter at least one value and one description"],
    phoneNumber : ["Numéro de téléphone", "Phone number"],
    mail : ["Mail", "E-mail"],
    role : ["Role", "Role"],
    selectRole : ["Selectionnez les rôles présents dans votre groupe", "Select the roles in your group"],
    selectRoleExemples : ["Ex.: Éleve, Professeur, Coiffeur, Client", "E.g.: Student, Teacher, Hairdresser, Customer"],
    available : ["disponible", "available"],
    addRole : ["Ajouter un role", "Add role"],
    user : ["Utilisateur", "user"],
    scanQRCode : ["Scanner un QR Code", "Scan a QR Code"],
    scanQRCodeDescritpion : ["Vous pourrez bientot ajouter des personnes simplement en scannant le QR Code de cette dernière.\n Patience ⏳ Ca arrive bientot !", "You'll soon be able to add new people simply by scanning their QR code. Patience ⏳ It's coming soon!"],
    addA : ["Ajouter un ", "Add a "],
    myAvailabilitiesTeaser : ["Bientôt disponible ! Précisez vos heures de disponibilité pour les rendez-vous afin de mieux gérer votre emploi du temps et éviter les rendez-vous tardifs ou inconvenants. Notre application pourra également vous proposer automatiquement les horaires de rendez-vous les plus optimaux en fonction de vos préférences et de votre emploi du temps. Restez maître de votre agenda et assurez-vous que vos rendez-vous soient planifiés selon vos préférences! 😊", "Available soon! Specify your availability times for appointments to better manage your schedule and avoid late or inconvenient appointments. Our application can also automatically suggest the most optimal appointment times according to your preferences and schedule. Stay in control of your schedule and make sure your appointments are planned according to your preferences! 😊"],
    myAvailabilitiesDescription : ["Tous vos partenaires de vie pour qui vous pouvez gérer leurs rendez-vous sans qu'ils ne puissent se créer eux-mêmes un compte (Exemples : Vos animaux de compagnie, vos enfants)", "All your life partners for whom you can manage their appointments without them being able to create an account themselves (Examples: Your pets, your children)"],
    sharePlanningTeaser : ["Tous vos partenaires de vie pour qui vous pouvez gérer leurs rendez-vous sans qu'ils ne puissent se créer eux-mêmes un compte (Exemples : Vos animaux de compagnie, vos enfants)", "All your life partners for whom you can manage their appointments without them being able to create an account themselves (Examples: Your pets, your children)"],
    sharePlanningDescription : ["Bientôt, vous pourrez partager votre planning avec vos proches en toute simplicité ! Sur cette page, vous pourrez choisir de partager vos disponibilités avec vos amis, votre famille ou vos collègues. Restez synchronisés et planifiez vos rendez-vous en toute confiance! 😁", "Soon, you'll be able to easily share your schedule with friends and family! On this page, you can choose to share your availability with friends, family or colleagues. Stay in sync and schedule your appointments with confidence! 😁"],
    availableSoon : ["Bientôt disponible ! 😊", "Available soon! 😊"],
    general : ["Informations générales", "General information"],
    noGroup : ["Aucun groupe", "No group"],
    noGroupDescription : ["Vous n'avez selectionné aucun groupe, personne ne pourra voir votre rendez-vous sauf les personnes que vous invitez", "If you haven't selected a group, no one will be able to see your appointment except the people you invite."],
    invalidAccount: ["Compte invalide", "invalid account"],
    history: ["Historique", "History"],
    historyDescription: ["Votre historique", "Your history"],
    timeStatement: ["Déclaration d'heure", "Time statement"],
    back: ["Retour", "Back"],
    resume: ["Résumé", "Resume"],
    ofDaytimeWork: ["de travail de jour", "of daytime work"],
    ofNighttimeWork: ["de travail de nuit", "of nighttime work"],
    ofRoute: ["de trajet", "of route"],
    update: ["Modifier", "Update"],
    save: ["Sauvegarder", "Save"],
};

export default lexique;