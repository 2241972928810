import { getMinuteNumberFromTime, getMonth, toTwoDigit } from "../../service/DatetimeUtil";
import UserProfilPicture from "../Common/UserProfilPicture";


function StatementSection(props) {

    if(!props.sectionStatements) return null;

    const statementRules = props.statementRules;

    var Statement = ({ item }) => {

        const statements = {
            timeStatements: item?.timeStatements||[]
        }

        if(!statements.timeStatements && !statements.displacementStatements) return null;

        let dayMinutes = 0;
        let nightMinutes = 0;
        let roadMinutes = 0;

        statements.timeStatements.forEach(subStatement => {
            //TODO: Il y a surement une manière plus opti de faire cete algo
            if(subStatement.statementType === "road") {   //Si trajet, on ne différencie pas les heures de nuit et de jour
                roadMinutes += subStatement.duration;
            } else if(subStatement.statementType === "work") {    //Si trajet, on différencie les heures de nuit et de jour

                const statementStartTime = subStatement.startTime;

                //Dans cet algorithme, on part du principe que la date de début de nuit sera tj avant minuit et que la date de fin de nuit sera tj après minuit
                if(statementStartTime < statementRules.nighttimeEnd) {
                    
                    if((getMinuteNumberFromTime(statementStartTime) + subStatement.duration) > getMinuteNumberFromTime(statementRules.nighttimeEnd)) {
                        const tmpMinuteDayWOfWork = getMinuteNumberFromTime(statementStartTime) + subStatement.duration - getMinuteNumberFromTime(statementRules.nighttimeEnd);
                        const tmpMinuteNightOfWork =  getMinuteNumberFromTime(statementRules.nighttimeEnd) - getMinuteNumberFromTime(statementStartTime);
                        dayMinutes += tmpMinuteDayWOfWork;
                        nightMinutes += tmpMinuteNightOfWork;
                    } else {
                        nightMinutes += subStatement.duration;
                    }

                } else if(statementStartTime >= statementRules.nighttimeStart) {//  si la date de début de la déclaration est avant la fin de la nuit (de minuit à 5h du matin par exemple) ou après le début de la nuit (de 22h à minuit par exemple)
                    nightMinutes += subStatement.duration;

                } else {    //Si la date de début est compris dans les heures de nuit ...
                    if((getMinuteNumberFromTime(statementStartTime) + subStatement.duration) > getMinuteNumberFromTime(statementRules.nighttimeStart)) {
                        //...Mais que la durée impose qu'il y ai une partie en heure de jour et une partie en heure de nuit
                        const tmpMinuteDayWOfWork = getMinuteNumberFromTime(statementRules.nighttimeStart) - getMinuteNumberFromTime(statementStartTime);
                        const tmpMinuteNightOfWork = getMinuteNumberFromTime(statementStartTime) + subStatement.duration - getMinuteNumberFromTime(statementRules.nighttimeStart);
                        dayMinutes += tmpMinuteDayWOfWork;
                        nightMinutes += tmpMinuteNightOfWork;
                    } else {
                        dayMinutes += subStatement.duration;
                    }
                }
            }
            
        });

        // let date = "";
        // let statusId = "";
        // let createdDate = "";

        // if(statements.timeStatements?.length > 0) {
        //     date = statements.timeStatements[0].date;
        //     statusId = statements.timeStatements[0].statusId;
        //     createdDate = statements.timeStatements[0].createdDate;
        // }

        // if(statements.displacementStatements?.length > 0) {

        //     date = statements.displacementStatements[0].date;

        //     if(statements.displacementStatements[0].createdDate > createdDate) {
        //         statusId = statements.displacementStatements[0].statusId;
        //     }
        // }

        return (
            <div style={{paddingVertical: 3}}>
                <div className='shadow borderadius' style={{width: '100%', marginTop: 10, height: 60, backgroundColor: 'white', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 10}}>
                    <UserProfilPicture size={40} picture={item?.image} pseudo={item?.firstName + " " + item?.lastName} backgroundColor={item?.backgroundColor}/>

                    <div style={{width: '20%'}}>
                        <h6 style={{marginLeft: 10}}>{item?.firstName + " " + item?.lastName}</h6>
                    </div>

                    <div className='shadow borderadius' style={{height: 30, backgroundColor: 'white', marginLeft: 10}}>
                        <p style={{padding: 5, paddingLeft: 10, paddingRight: 10}}>{Math.floor(dayMinutes/60)}h{toTwoDigit(dayMinutes%60)||""} de jour ☀️</p>
                    </div>

                    <div className='shadow borderadius' style={{height: 30, backgroundColor: 'white', marginLeft: 10}}>
                        <p style={{padding: 5, paddingLeft: 10, paddingRight: 10}}>{Math.floor(nightMinutes/60)}h{toTwoDigit(nightMinutes%60)||""} de nuit 🌃</p>
                    </div>

                    <div className='shadow borderadius' style={{height: 30, backgroundColor: 'white', marginLeft: 10}}>
                        <p style={{padding: 5, paddingLeft: 10, paddingRight: 10}}>{Math.floor(roadMinutes/60)}h{toTwoDigit(roadMinutes%60)||""} de trajet 🚗</p>
                    </div>

                </div>
            </div>  
        )
    }

    var StatementsSection = ({ date,  item }) => {

        return (
            <div>
                <p className="h5">{date.substring(8, 10)} {getMonth(new Date(date).getMonth()+1, {language: 0}, true)} {date.substring(0, 4)}</p>
                
                {
                    Object.keys(item)?.map((key, index) => <Statement key={index} item={item[key]}/>)
                }
            </div>
        )
    }

    return Object.keys(props.sectionStatements)?.map((key, index) => <StatementsSection key={index} date={key} item={props.sectionStatements[key]}/>);    
}

export default StatementSection;