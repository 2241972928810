import fetchKeyHouseApi from '../../service/KeyHouseFetcher.js';

//POST
export const getUserAuthenticationToken = async (mail, password) => {
    return await fetchKeyHouseApi('authentication/role', undefined, [], 'POST', {}, {"mail": mail, "password": password})
};

export const getUserToken = async (mail, password) => {
    return await fetchKeyHouseApi('authentication/accesstoken', "VOLONTARY_UNDEFINED", [], 'POST', {'Content-Type': 'application/json'}, {'mail': mail, 'password': password})
};
