import { useEffect, useState } from 'react';
import BackArrow from '../Common/BackArrow';
import UserProfilPicture from '../Common/UserProfilPicture';
import ExportExcel from "./ExportExcel";
// import Checkbox from '../Common/CheckBox';

import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr"; // the locale you want

import "react-datepicker/dist/react-datepicker.css";
import StatementSection from './StatementSection';
import { getDate } from '../../service/DatetimeUtil';

function GroupDetails(props) {

    registerLocale("fr", fr); // register it with the name you want

    let [selectedMembers, setSelectedMembers] = useState([]);
    let [sectionStatements, setSectionStatements] = useState(undefined);
    let [sectionStatementsToShow, setSectionStatementsToShow] = useState([]);

    var date = new Date();
    let [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    let [endDate, setEndDate] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));

    useEffect(() => {
        selectAll();

        const groupMembers = props.groupDetails?.members;

        let sectionAppointments = props.groupDetails?.timeStatements?.reduce((accumulator, item) => {
            const date = item.date;
            if (!accumulator[date]) {
              accumulator[date] = {};
            }

            if (!accumulator[date][item.userId]) {

                const memberDetails = groupMembers.filter(m => m.userId === item.userId);
                // alert(JSON.stringify(item.userId))
                // alert(JSON.stringify(memberDetails.map(m => ({userId: m.userId, firstName: m.firstName}))))

                if(!memberDetails[0]) {
                    return accumulator; 
                }

                accumulator[date][item.userId] = {
                    firstName: memberDetails[0].firstName,
                    lastName: memberDetails[0].lastName,
                    image: memberDetails[0].image,
                    backgroundColor: memberDetails[0].backgroundColor,
                    timeStatements: []
                };
            }

            accumulator[date][item.userId].timeStatements.push(item);
            
            return accumulator;
        }, {});

        setSectionStatements(sectionAppointments);
    }, [props.groupDetails])

    useEffect(() => {

        if(sectionStatements) {
            let tmpToShow = {};

            Object.keys(sectionStatements).forEach(function(key, index) {
    
                // Filter statements by selected dates
                if(key >= getDate(startDate) && key <= getDate(endDate)) {

                    // Filter statements by selected members
                    tmpToShow[key] =  Object.keys(sectionStatements[key])
                                            .filter(key => selectedMembers.map(m => m.userId).includes(key))
                                            .reduce((obj, key2) => {
                                                obj[key2] = sectionStatements[key][key2];
                                                return obj;
                                            }, {});
                }
            });
            setSectionStatementsToShow(tmpToShow);
        }

    }, [sectionStatements, startDate, endDate, selectedMembers])

    if(!props.show) return null;

    function selectMember(member) {
        setSelectedMembers(sm => {
            if(sm.map(m => m.userId).includes(member.userId)) {
                return sm.filter(m => m.userId !== member.userId);
            } else {
                return [...sm, ...[member]];
            }
        })
    }

    function selectAll() {
        setSelectedMembers(props.groupDetails?.members||[])
    }

    function unselectAll() {
        setSelectedMembers([])
    }
    
    var Member = ({member}) => {
        return (
            <div className='shadow borderadius' style={{width: '100%', marginTop: 10, height: 100, backgroundColor: 'white', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 10}}>
                <UserProfilPicture size={80} picture={member.image} pseudo={member.firstName + " " + member.lastName} backgroundColor={member.backgroundColor}/>
                
                <div style={{width: '35%'}}>
                    <h4 style={{marginLeft: 10}}>{member.firstName + " " + member.lastName}</h4>
                </div>

                <input checked={selectedMembers.map(m => m.userId).includes(member.userId)} onChange={() => selectMember(member)} type="checkbox" style={{color: 'red', marginLeft: 'auto', marginRight: 30}}/>
                {/* <Checkbox checked={true}/> */}
            </div>
        )
    }

    return (
        <div className="wrap horizontalcenter" style={{width: '100%'}}>
            <div style={{marginRight: 'auto', display: 'flex', justifyContent: 'row', width: '100%'}}>
                <BackArrow backCommand={() => props.setSelectedGroupId(undefined)}/>

                <ExportExcel groupDetails={props.groupDetails} selectedMembers={selectedMembers} startDate={startDate} endDate={endDate}/>
            </div>

            {/* <p>{JSON.stringify(props.groupDetails.members)}</p> */}

            <div style={{display: 'flex', flexDirection: 'row', width: '96%'}}>
                <div style={{width: '50%', marginRight: 10}}>
                    <div style={{display: 'flex', justifyContent: 'row'}}>
                        <h3 className='section'>Membres</h3>

                        <button style={{height: 30, alignSelf: 'center', marginLeft: 'auto', marginRight: 30, borderRadius: 8, color: 'white', backgroundColor: '#F6B60D'}} onClick={unselectAll}>Tout déselectionner</button>
                        <button style={{height: 30, alignSelf: 'center', marginLeft: 'auto', marginRight: 30, borderRadius: 8, color: 'white', backgroundColor: '#F6B60D'}} onClick={selectAll}>Tout selectionner</button>
                    </div>

                    <div className='shadow borderadius' style={{height: 500, width: '100%', overflowY: 'scroll', padding: 10, marginLeft: 10}}>
                        {
                            props.groupDetails?.members?.map(m => {
                                return (
                                    <Member member={m}/>
                                )
                            })
                        }
                    </div>
                </div>
                <div style={{width: '50%', marginLeft: 10}}>
                    <div style={{display: 'flex', justifyContent: 'row', alignItems: 'center'}}>
                        <h3 className='section'>Déclarations</h3>
                        <p style={{textAlign: 'center', height: 24}}>Du </p>
                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="dd MMMM yyyy" locale="fr"/>
                        
                        <p style={{textAlign: 'center', height: 24}}>au </p>
                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} dateFormat="dd MMMM yyyy" locale="fr"/>
                    </div>

                    <div className='shadow borderadius' style={{height: 500, width: '100%', overflowY: 'scroll', padding: 10, marginLeft: 10}}>
                        
                        <StatementSection sectionStatements={sectionStatementsToShow} statementRules={props.groupDetails?.groupRules ? props.groupDetails?.groupRules[0] : undefined}/>
                    </div>
                </div>

            </div>
        </div>
      );
}

export default GroupDetails;