import axios from 'axios';

const apiUrl = "https://talkandpoke.com/plamaapi/";  //TODO

const fetchPlamaApi = async (endpoint, accessToken, params, requestMethod, headers = {}, body = {}) => {

    if(!accessToken) {
        //Alert.alert("Erreur d'authentification", "Nous n'avons pas réussi à vous authentifier, nous allons ré-essayer, veuillez vérifier que vous êtes bien connecté à un réseau avec accès interenet, merci 😊");
        return null;
    }

    let url = apiUrl + endpoint;

    if(params) {
        for(let i = 0; i < params.length; i++) {
            if(params[i][0] && params[i][1]) {
                url = url + (i === 0 ? "?" : "&")  + encodeURIComponent(params[i][0]) + '=' + encodeURIComponent(params[i][1]);
            }
        }
    }

    if(!headers) headers = {};

    //Object.assign(headers, {'App-Version': pkg.expo.android.versionCode});
    Object.assign(headers, {'Application-Identification': "b425fbc7-7145-11ef-81d6-e0071bf394b0"});
    Object.assign(headers, {'Authorization': "Bearer " + accessToken});
    
    function newAbortSignal(timeoutMs) {
        const abortController = new AbortController();
        setTimeout(() => abortController.abort(), timeoutMs || 0);
      
        return abortController.signal;
    }

    let requestParams = {
        method: requestMethod,
        url: url,
        "Response-Type": "application/json",
        signal: newAbortSignal(10000),
        headers: headers
    }

    if(Object.keys(body).length !== 0) {
        requestParams = {...requestParams, ...{data: JSON.stringify(body)}}
    }

    console.log(requestParams)
    return await axios.request(requestParams)
    .then((response) => {
        log(url, response.data, requestMethod, body, headers);
        return response.data;
    })
    .catch((error) => {
        if (axios.isCancel(error)) {
            console.log('Request to Plama API was canceled due to timeout ' + JSON.stringify(error));
            return JSON.parse('{"code":"TIMEOUT_ERROR"}');

        } else if (!error.response) {
            console.log("Network error ! " + JSON.stringify(error));
            return JSON.parse('{"code":"NETWORK_ERROR"}');
        }

        logerror(url, error, requestMethod);
        return JSON.parse('{"status":"' + error.status + '","code":"' + error.code + '"}');
    });
};

function log(url, data, requestMethod, body, headers) {
    console.log("=================================================================");
    console.log("Request : " + JSON.stringify(url));
    console.log("Method : " + JSON.stringify(requestMethod));
    console.log("Response : " + JSON.stringify(data).substring(0, 750));
    console.log("Body : " + JSON.stringify(body));
    //console.log("Headers : " + JSON.stringify(headers).substring(0, 750));
    console.log("=================================================================");
}

function logerror(url, error, requestMethod) {
    console.error("=================================================================");
    console.error("Request : " + JSON.stringify(url));
    console.error("Method : " + JSON.stringify(requestMethod));
    console.error("Response : " + JSON.stringify(error.code));
    console.error("=================================================================");
}

export { fetchPlamaApi };