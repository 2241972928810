import { useState } from "react";
import ClockInEaseLayout from "./ClockInEaseLayout";
import Login from "./Login";
import BackOffice from "./BackOffice";

function Dashboard(props) {

    let [authenticationToken, setAuthenticationToken] = useState(undefined);

    return (
        <ClockInEaseLayout>
            <div className="center">

                {
                    !authenticationToken ? 
                    <Login setAuthenticationToken={setAuthenticationToken}/>
                    : <BackOffice authenticationToken={authenticationToken}/>
                }
                
            </div>
        </ClockInEaseLayout>
      );
}

export default Dashboard;


