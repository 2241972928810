import { useEffect, useState } from "react";

import { getUserGroups } from "../../api/plamaApi/GroupApi";
import { getGroupStatements } from "../../api/plamaApi/StatementApi";
import Groups from "./Groups";
import GroupDetails from "./GroupDetails";

function BackOffice(props) {

    let [userGroups, setUserGroups] = useState([]);
    let [userGroupsDetails, setUserGroupsDetails] = useState([]);

    let [selectedGroupId, setSelectedGroupId] = useState(undefined);
    let [selectedGroup, setSelectedGroup] = useState(undefined);
    
    useEffect(() => {

        if(props.authenticationToken) {

            async function getData() {
                setUserGroups(await getUserGroups(props.authenticationToken));
            }

            getData();
            
        }
        
    }, [props.authenticationToken])

    useEffect(() => {

        if(selectedGroupId) {
            if(userGroupsDetails.map(sgd => sgd.groupId).includes(selectedGroupId)) {
                setSelectedGroup(userGroupsDetails.filter(g => g.groupId === selectedGroupId)[0]);
            } else {
                loadGroupDetails(selectedGroupId);
            }
        } else {
            setSelectedGroup(undefined);
        }

        async function loadGroupDetails(groupId) {
            const groupDetails = await getGroupStatements(groupId, props.authenticationToken);
    
            setSelectedGroup(groupDetails);
            setUserGroupsDetails(a => [...a, ...[groupDetails]]);
        }
    }, [selectedGroupId])

    return (
        <div style={{width: '100%'}}>
            {/* <p>{JSON.stringify(userGroups)}</p> */}
            <Groups groups={userGroups} setSelectedGroupId={setSelectedGroupId} show={!selectedGroup}/>
            <GroupDetails groupDetails={selectedGroup} setSelectedGroupId={setSelectedGroupId} show={selectedGroup}/>
        </div>
      );
}

export default BackOffice;


