import { useState } from "react";
import { createSupport } from "../service/SupportService";
import Layout from "./Layout";

function CreatorRequest(props) {

    const queryParameters = new URLSearchParams(window.location.search)

    let [showCreatorDescription, setShowCreatorDescription] = useState(queryParameters.get("description") === "true" ? true : false);

    function BecomeCreator({ show }) {

        let [response, setResponse] = useState(false);
        let [mail, setMail] = useState("");
        let [description, setDescription] = useState("");
    
        async function sendSupportRequest() {

            if(mail !== "" && description !== "") {

                let resp = await createSupport(mail, description);
    
                let error = false;
        
                if(!resp || resp.status !== "OK") error = true;
        

                if(error) {
                    setResponse("Une erreur est survenue, veuillez réessayer dans quelques minutes");
                } else {
                    setResponse("Merci d'avoir contacté le support, nous vous re-contacterons le plus rapidement possible");
                    setMail("");
                    setDescription("");
                }
            } else {
                setResponse("Les champs mail et description doivent être remplis pour envoyer une demande support, merci d'avance")
            }
            
        }

        if(!show) return null;

        return (
            <div>
                <h1 className="h3 mb-3 font-weight-normal">Devenir Créateur ! </h1>
                <p style={{maxWidth: 300}}>{response}</p>
                <label className="sr-only" htmlFor="inputEmail">Email address</label>
                <input value={mail} onChange={(e) => setMail(e.target.value)} className="form-control" type="email" name="mail" id="inputEmail" placeholder="Votre adresse mail" required="required" autofocus="autofocus" />
                <label className="sr-only" htmlFor="inputPassword">Description</label>
                <textarea value={description} onChange={(e) => setDescription(e.target.value)} className="parea-form-control" type="p" name="description" id="inputPassword" placeholder="Dites nous pourquoi vous souhaitez devenir Créateur ! 👨‍🎨 Décrivez nous vos projets et vos oeuvres 🥰 (ne communiquez jamais votre mot de passe)" required="required" defaultValue={""} style={{marginTop: 10}}/>
                <button onClick={() => sendSupportRequest()} className="btn btn-lg btn-primary btn-block buttonClass" type="submit">Envoyer</button>

                <p onClick={() => setShowCreatorDescription(true)} style={{textDecoration: 'underline', marginTop: 20, display: 'flex'}}>C'est quoi Créateur en fait ?</p>
            </div>
        )
    }

    function CreatorDescription({ show }) {

        if(!show) return null;

        return (
            <div style={{margin: 50}}>
                <p style={{fontWeight: "bold", marginBottom: 10}}>Devenez Créateur dans l'univers Talk&Poke : Inspirez, Éduquez, Divertissez !</p>

                <p style={{marginBottom: 10}}>Si vous aimez partager des idées captivantes, enseigner ou créer du divertissement, rejoignez-nous en tant que Créateur dans notre communauté Talk&Poke.</p>

                <p style={{marginBottom: 10}}>En tant que Créateur, vous pouvez donner vie à vos idées à travers des affiches originales qui inspirent, éduquent et divertissent. Votre rôle est de stimuler des discussions, des débats et des connexions au sein de notre communauté.</p>

                <p style={{marginBottom: 10}}>En devenant Créateur sur Talk&Poke, vous bénéficiez d'une visibilité exceptionnelle, vous partagez votre expertise, vous explorez votre créativité et interagissez avec une communauté engagée.</p>

                <p style={{marginBottom: 10}}>Pour postuler, Cliquez simplement sur bouton si dessous  "Devenir Créateur !" et complétez le formulaire. Rejoignez-nous pour créer un avenir inspirant sur Talk&Poke dès aujourd'hui !</p>

                <p style={{marginBottom: 10}}>Avec enthousiasme,</p>
                <p style={{marginBottom: 10}}>L'équipe Talk&Poke</p>

                <div onClick={() => setShowCreatorDescription(false)} style={{backgroundColor: '#FEA52A', minWidth: 10, width: 150, paddingVertical: 10, borderRadius: 100, marginLeft: 'auto', marginRight: 'auto', marginTop: 50, marginBottom: 100}}>
                    <p style={{color: 'white'}}>Devenir créateur !</p>
                </div>
            </div>
        )
    }

    return (
        <Layout>
    <       div className={showCreatorDescription ? null : "center"} style={{width: '100vw', backgroundColor: 'white'}}>
                <div>
                    <BecomeCreator show={!showCreatorDescription}/>

                    <CreatorDescription show={showCreatorDescription}/>
                </div>
            </div>
        </Layout>
      );
}

export default CreatorRequest;

