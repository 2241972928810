import React, { useState, useEffect } from 'react';

import Posters from "../Common/Posters";
import PosterDetails from "../Common/PosterDetails";
import { getCreatorPosters } from '../../service/PosterService';

function CreatorDashboard(props) {

    let [posters, setPosters] = useState([]);
    let [selectedPoster, setSelectedPoster] = useState(undefined);

    useEffect(() => {
        async function getData() {

            if(props.accessToken) {
                setPosters(await getCreatorPosters(props.accessToken));
            }
        }
        
        getData()

    }, [props.accessToken])

    if(!props.show) return null;

    return (
        <div>
            <h1 style={{margin: 10}}>Dashboard créateur</h1>
            <h2 style={{margin: 10}}>Vos posters</h2>
            <Posters posters={posters} setSelectedPoster={setSelectedPoster} show={!selectedPoster}/>
            <PosterDetails poster={selectedPoster} setSelectedPoster={setSelectedPoster} show={selectedPoster}/>
        </div>
      );
}

export default CreatorDashboard;

