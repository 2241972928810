import { useState } from "react";
import { createSupport } from "../service/SupportService";
import Layout from "./Layout";

function Support(props) {

    let [response, setResponse] = useState(false);
    let [mail, setMail] = useState("");
    let [description, setDescription] = useState("");

    async function sendSupportRequest() {

        if(mail !== "" && description !== "") {
            let resp = await createSupport(mail, description);

            let error = false;
    
            if(!resp || resp.affectedRows !== 1) error = true;
    
            if(error) {
                setResponse("Une erreur est survenue, veuillez réessayer dans quelques minutes");
            } else {
                setResponse("Merci d'avoir contacté le support, nous vous re-contacterons le plus rapidement possible");
                setMail("");
                setDescription("");
            }
        } else {
            setResponse("Les champs mail et description doivent être remplis pour envoyer une demande support, merci d'avance")
        }
        
    }

    return (
        <Layout>
            <div className="center">
                <div>
                {/* <form className="form-signin" method="POST" action="/support"> */}
                    <h1 className="h3 mb-3 font-weight-normal">Support</h1>
                    <p style={{maxWidth: 300}}>{response}</p>
                    <label className="sr-only" htmlFor="inputEmail">Email address</label>
                    <input value={mail} onChange={(e) => setMail(e.target.value)} className="form-control" type="email" name="mail" id="inputEmail" placeholder="Votre adresse mail" required="required" autofocus="autofocus" />
                    <label className="sr-only" htmlFor="inputPassword">Password</label>
                    <textarea value={description} onChange={(e) => setDescription(e.target.value)} className="textarea-form-control" type="text" name="description" id="inputPassword" placeholder="Décrivez votre besoin avec le plus de détails (ne communiquez jamais votre mot de passe)" required="required" defaultValue={""} style={{marginTop: 10}}/>
                    <button onClick={() => sendSupportRequest()} className="btn btn-lg btn-primary btn-block buttonClass" type="submit">Envoyer</button>
                {/* </form> */}
                </div>
            </div>
        </Layout>
      );
}

export default Support;

