import Layout from "./Layout";

function Home(props) {

    return (
        <Layout>
            <div className="center">
                <p style={{margin: 50}}>
                    Plongez dans un monde de joie, de connectivité et d'excitation avec notre incroyable application mobile, désormais disponible sur l'Apple Store et le Play Store ! 📱 Notre plateforme sociale dynamique vous invite à rejoindre une communauté vibrante où les sourires sont contagieux. 😃✨ Réagissez à des affiches, faites la fête avec vos amis, ou rencontrez de nouvelles âmes éclatantes de partout dans le monde. 🌍🥳 Avec des jeux amusants, des chats vivants, et des profils captivants, chaque jour est une aventure joyeuse. Exprimez-vous, découvrez des compatibilités magiques, et créez des souvenirs inoubliables. 🎉💬🎮 Téléchargez notre application aujourd'hui et laissez la joie envahir votre monde ! 🌟😊
                </p>
            </div>
        </Layout>
      );
}

export default Home;


