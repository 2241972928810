import lexique from "../assets/data/Lexique";

export const getDateTime = (datetime = new Date()) => {

    return    toTwoDigit(datetime.getFullYear()) + "-"
            + toTwoDigit((datetime.getMonth()+1))  + "-" 
            + toTwoDigit(datetime.getDate()) + " "  
            + toTwoDigit(datetime.getHours()) + ":"  
            + toTwoDigit(datetime.getMinutes()) + ":" 
            + toTwoDigit(datetime.getSeconds());
}

export const getDate = (datetime = new Date()) => {

    return    toTwoDigit(datetime.getFullYear()) + "-"
            + toTwoDigit((datetime.getMonth()+1))  + "-" 
            + toTwoDigit(datetime.getDate());
}

export const getTime = (datetime = new Date()) => {

    return    toTwoDigit(datetime.getHours()) + ":"  
            + toTwoDigit(datetime.getMinutes()) + ":" 
            + toTwoDigit(datetime.getSeconds());
}

export const minuteToHourAndMinute = (minute) => {
    let minutes = toTwoDigit(minute%60);
    let hours = Math.floor(minute/60)
    return (hours ? (hours + 'h') : "") + (minutes !== "00" ? (minutes + "min") : "")
}


export const isSevenDaysOrLess = (datetime) => {
    
    var today = new Date();
    var nextWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);

    if (nextWeek > new Date(datetime)){
        return false;
    }else{
        return true;
    }
}

export const getMonth = (intMonth, context, full = false) => {

    switch(intMonth) {
        case 1:
            return full ? lexique.january[context.language] : lexique.january3[context.language];
        case 2:
            return full ? lexique.february[context.language] : lexique.february3[context.language];
        case 3:
            return full ? lexique.march[context.language] : lexique.march3[context.language];
        case 4:
            return full ? lexique.april[context.language] : lexique.april3[context.language];
        case 5:
            return full ? lexique.may[context.language] : lexique.may3[context.language];
        case 6:
            return full ? lexique.june[context.language] : lexique.june3[context.language];
        case 7:
            return full ? lexique.july[context.language] : lexique.july3[context.language];
        case 8:
            return full ? lexique.august[context.language] : lexique.august3[context.language];
        case 9:
            return full ? lexique.september[context.language] : lexique.september3[context.language];
        case 10:
            return full ? lexique.october[context.language] : lexique.october3[context.language];
        case 11:
            return full ? lexique.november[context.language] : lexique.november3[context.language];
        case 12:
            return full ? lexique.december[context.language] : lexique.december3[context.language];
        default:
            return null;
    }
}

export const getDay = (intDay, context) => {

    switch(intDay) {
        case 0:
            return lexique.sunday[context.language];
        case 1:
            return lexique.monday[context.language];
        case 2:
            return lexique.tuesday[context.language];
        case 3:
            return lexique.wednesday[context.language];
        case 4:
            return lexique.thursday[context.language];
        case 5:
            return lexique.friday[context.language];
        case 6:
            return lexique.saturday[context.language];
        default:
            return null;
    }
}

export const getNextDay = (date, context) => {  //TODO: name not really explicite

    const now = new Date();
    // const tomorrow = new Date()
    // tomorrow.setDate(tomorrow.getDate() + 1);
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1);

    // if(date.getTime() <= now.getTime()) {
    //     return lexique.now[context.language];
    // }

    if(date.setHours(0,0,0,0) === now.setHours(0,0,0,0)) {
        return lexique.today[context.language];
    }

    // if(date.setHours(0,0,0,0) == tomorrow.setHours(0,0,0,0)) {
    //     return lexique.tomorrow[context.language];
    // }

    if(date.setHours(0,0,0,0) === yesterday.setHours(0,0,0,0)) {
        return lexique.yesterday[context.language];
    }

    switch (context.language) {
        case 0:
            return getDay(date.getDay(), context) + " " + lexique.previous[context.language];
        
        default :
            return lexique.next[context.language] + " " + getDay(date.getDay(), context);
    }

    
}

export const toTwoDigit = (number) => {

    if(number < 10) {
        return "0" +  number
    } else {
        return number;
    }
}

export const getNumberOfDayInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
};

export const getFirstDayNumber = (year, month) => {
	const date = new Date(year, month, 1);
    var numberdayweek = [6,0,1,2,3,4,5];
  	return numberdayweek[date.getDay()]; // getting day of the week (counted from 0 to 6 - Monday as first)
};

export const getBeautifulDate = (beginDateTime, context, full = false) => {
    if(!beginDateTime) return "";

    return getDay(beginDateTime.getDay(), context) + " " + beginDateTime.getDate() + " " + getMonth(beginDateTime.getMonth()+1, context, full);
}

export const getBeautifulTimeFromDate = (beginDateTime) => {

    if(!beginDateTime) return "";

    return toTwoDigit(beginDateTime.getHours()) + "h" + toTwoDigit(beginDateTime.getMinutes());
}

export const getBeautifulTimeFromString = (beginDateTime) => {

    if(!beginDateTime || beginDateTime.length <= 5) return "";

    return beginDateTime.substring(0, 2) + "h" + beginDateTime.substring(3, 5);
}

export const getBeautifulDateTime = (beginDateTime, context) => {
    if(!beginDateTime) return "";

    return toTwoDigit(beginDateTime.getHours()) + "h" + toTwoDigit(beginDateTime.getMinutes()) + " " + getDay(beginDateTime.getDay(), context) + " " + beginDateTime.getDate() + " " + getMonth(beginDateTime.getMonth()+1, context);
}

export const getBeautifulDuration = (duration) => {
    if(!duration) return null;
    
    if(duration < 60) {
        return duration + " min";
      } else {
        let hour = Math.floor(duration/60);
  
        return hour + "h " + (duration - hour*60 ? (duration - hour*60) : "");
      }
}

export const arrayRange = (start, stop, step) =>
    Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
);

export const getMinuteNumberFromDatetime = (date) => {
    if(!date) return 0;
    
    if(date instanceof Date) {
        date = toTwoDigit(date.getHours()) + ":" + toTwoDigit(date.getMinutes())
    } else {
        date = date.substring(11, 16);
    }
    return (parseInt(date.split(":")[0]) * 60) + parseInt(date.split(":")[1]);
}

export const getMinuteNumberFromTime = (date) => {
    if(!date) return undefined;
    
    if(date instanceof Date) {
        date = JSON.stringify(date).substring(12, 17);
    }
    return (parseInt(date.split(":")[0]) * 60) + parseInt(date.split(":")[1]);
}

export const getDateFromMinuteNumber = (number) => {
    return new Date(0, 0, 0, (number/60)+1, number%60);
}

export const getEndHourString = (startTime, duration) => {
    
    const EndHourNumber = getMinuteNumberFromTime(startTime) + duration

    return toTwoDigit(Math.floor(EndHourNumber/60)) + ":" + toTwoDigit(EndHourNumber%60);
}

export const isStatementOverriddingStatements = (newStatement, statements) => {
    var newSStart = getMinuteNumberFromTime(newStatement.startTime);
    var newSEnd = newSStart + newStatement.duration;
    
    return statements.filter(s => {
        var oldSStart = getMinuteNumberFromTime(s.startTime);
        var oldSEnd = oldSStart + s.duration;
        
        return (newSStart <= oldSStart && newSEnd > oldSStart)
                || (newSStart < oldSEnd && newSEnd >= oldSEnd);
    }).length !== 0;
}

export const getWeekNumber = (date) => {
    // Copy date so don't modify original
    let d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    // Return array of year and week number
    return weekNo;
}

export const getStartDateOfWeek = (w, y) => {

    var d = (1 + (w - 1) * 7); // 1st of January + 7 days for each week

    return new Date(y, 0, d);
}

export const getEndDateOfWeek = (w, y) => {

    var d = (1 + (w - 1) * 7); // 1st of January + 7 days for each week

    return new Date(y, 0, d + 6);
}

export const generateDatesBetweenTwoDate = (startDate, stopDate) => {
    Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }
    
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate) {
        dateArray.push(new Date (currentDate));
        currentDate = currentDate.addDays(1);
    }
    return dateArray;
}