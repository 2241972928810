import { useState } from "react";
import AdminLogin from "./AdminLogin";
import DashBoard from "./DashBoard";
import Layout from "../Layout";

function BackOffice(props) {

    let [authenticationToken, setAuthenticationToken] = useState(undefined);

    return (
        <Layout>
            <div className="center">
                <AdminLogin setAuthenticationToken={setAuthenticationToken} show={!authenticationToken}/>

                <DashBoard show={authenticationToken}/>
            </div>
        </Layout>
      );
}

export default BackOffice;

