import React from 'react';
import BackArrowPng from '../../resources/ChevronLeft.png';

function BackArrow(props) {
    
    return (
        <div onClick={() => props.backCommand()} style={{height: 50, width: 50, background: 'orange', borderRadius: 100, margin: 20}}>
            <img style={{width: 50, height: 50}} src={BackArrowPng} alt={"Fleche de retour"}></img>
        </div>
      );
}

export default BackArrow;

