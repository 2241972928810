import { useState } from "react";

import CreatorDashboard from "./CreatorDashboard";
import CreatorLogin from "./CreatorLogin";
import Layout from "../Layout";

function Creator(props) {

    let [accessToken, setAccessToken] = useState(undefined);

    return (
        <Layout>
            <div style={{width: '100vw'}}>
                <div className="center">
                    <CreatorLogin setAuthenticationToken={setAccessToken} show={!accessToken}/>
                </div>

                <CreatorDashboard accessToken={accessToken} show={accessToken}/>
            </div>
        </Layout>
      );
}

export default Creator;

