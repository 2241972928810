import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { generateDatesBetweenTwoDate, getBeautifulDate, getDate, getEndDateOfWeek, getMinuteNumberFromTime, getStartDateOfWeek, getWeekNumber } from '../../service/DatetimeUtil';

//REFACTOR THIS SHIT

const ExportExcel = ({ groupDetails, selectedMembers, startDate, endDate }) => {

    if(!groupDetails) return null;

    const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

    const exportToExcel = async () => {

        // EXPECTED FORMAT : 
        //
        // const sheets = [
        //     {
        //         name: "Hugo Cabaret",
        //         userId: "1234567890",
        //         weeks: [
        //             {
        //                 weekNumber: 45,
        //                 dayHours: 35,
        //                 nightHours: 10,
        //                 overtime: 3,
        //                 roadHours: 2,
        //                 zone1A: 3,
        //                 zone1B: 3,
        //                 zone2: 3,
        //                 zone3: 3,
        //                 zone4: 3,
        //                 zone5: 3,
        //                 smallTrips: 3,
        //                 longTrips: 3
        //             }
        //         ],
        //         weeksCount: 4,
        //         days: [
        //             {
        //                 date: "2024-10-07",
        //                 dayHours: 8,
        //                 nightHours: 1,
        //                 overtime: 3,
        //                 roadHours: 2,
        //                 zone1A: 0,
        //                 zone1B: 1,
        //                 zone2: 0,
        //                 zone3: 0,
        //                 zone4: 0,
        //                 zone5: 0,
        //                 smallTrips: 3,
        //                 longTrips: 3
        //             }
        //         ], 
        //         daysCount: 31,
        //         details: [
        //             //ON S'EN BLC
        //         ]
        //     }
        // ]

        const sheetsDetails = [];
        const groupRules = groupDetails.groupRules && Array.isArray(groupDetails.groupRules) ? groupDetails.groupRules[0] : null;

        const startDateString = getDate(startDate);
        const endDateString = getDate(endDate);
        //GENERATE DATA STRUCTURE FOR TIMESTATEMENTS
        groupDetails.timeStatements?.forEach(ts => {

            if(ts.date >= startDateString && ts.date <= endDateString && selectedMembers.map(m => m.userId).includes(ts.userId)) {
                
                try {
                    //CREATE ALL PAGES AND DATE TEMPLATE WITHOUT DATA
                    const member = groupDetails.members.filter(m => m.userId === ts.userId)[0];

                    if(!sheetsDetails.find(sd => sd.userId === ts.userId)) {

                        let startDateOfWeek = new Date(startDate);
                        let endDateOfWeek = undefined;
                        let weekNumber = getWeekNumber(startDate);
                        let weeks = [];
                        let count = 0;

                        do {

                            endDateOfWeek = getEndDateOfWeek(weekNumber, new Date(startDateOfWeek).getFullYear());
                            
                            weeks.push(
                                {
                                    weekNumber: weekNumber,
                                    startDate: startDateOfWeek,
                                    endDate: endDateOfWeek < new Date(endDate) ? endDateOfWeek : new Date(endDate),
                                    dayHours: 0,
                                    nightHours: 0,
                                    overtime: 0,
                                    roadHours: 0,
                                    zone1A: 0,
                                    zone1B: 0,
                                    zone2: 0,
                                    zone3: 0,
                                    zone4: 0,
                                    zone5: 0,
                                    smallTrips: 0,
                                    longTrips: 0
                                }
                            )
                            count++;
                            weekNumber++;
                            startDateOfWeek = getStartDateOfWeek(weekNumber, new Date(startDateOfWeek).getFullYear());
                        } while (endDateOfWeek < new Date(endDate) && count < 100);

                        sheetsDetails.push(
                            {
                                name: member.firstName + ' ' + member.lastName,
                                userId: member.userId,
                                weeks: weeks,
                                weeksCount: weeks.length,
                                days: generateDatesBetweenTwoDate(new Date(startDate), new Date(endDate))
                                            .map(date => (
                                                {
                                                    date: date,
                                                    dayHours: 0,
                                                    nightHours: 0,
                                                    overtime: 0,
                                                    roadHours: 0,
                                                    zone1A: 0,
                                                    zone1B: 0,
                                                    zone2: 0,
                                                    zone3: 0,
                                                    zone4: 0,
                                                    zone5: 0,
                                                    smallTrips: 0,
                                                    longTrips: 0
                                                }
                                            )),
                                daysCount: generateDatesBetweenTwoDate(new Date(startDate), new Date(endDate)).length,
                                details: []
                            }
                        )
                    }

                    //ADD DYNAMICALLY DATA
                    const sheetDetails = sheetsDetails.find(m => m.userId === member.userId);
                    
                    let dayRow = sheetDetails.days.find(d => getDate(d.date) === ts.date);
                    let weekRow = sheetDetails.weeks.find(d => ts.date >= getDate(d.startDate) && ts.date <= getDate(d.endDate));

                    const valueToAdd = Math.round((ts.duration/60) * 10) / 10;
                    
                    if(ts.statementType === "road") {   //Si trajet, on ne différencie pas les heures de nuit et de jour
                        dayRow.roadHours += valueToAdd;
                        weekRow.roadHours += valueToAdd;
                    } else if(ts.statementType === "work") {    //Si travail, on différencie les heures de nuit et de jour
    
                        const statementStartTime = ts.startTime;
    
                        //Dans cet algorithme, on part du principe que la date de début de nuit sera tj avant minuit et que la date de fin de nuit sera tj après minuit
                        if(statementStartTime < groupRules.nighttimeEnd) {
                            if((getMinuteNumberFromTime(statementStartTime) + ts.duration) > getMinuteNumberFromTime(groupRules.nighttimeEnd)) {
                                const tmpMinuteDayWOfWork = getMinuteNumberFromTime(statementStartTime) + ts.duration - getMinuteNumberFromTime(groupRules.nighttimeEnd);
                                const tmpMinuteNightOfWork =  getMinuteNumberFromTime(groupRules.nighttimeEnd) - getMinuteNumberFromTime(statementStartTime);


                                dayRow.dayHours += Math.round((tmpMinuteDayWOfWork/60) * 10) / 10;
                                weekRow.dayHours += Math.round((tmpMinuteDayWOfWork/60) * 10) / 10;
                                dayRow.nightHours += Math.round((tmpMinuteNightOfWork/60) * 10) / 10;
                                weekRow.nightHours += Math.round((tmpMinuteNightOfWork/60) * 10) / 10;
                            } else {
                                dayRow.nightHours += valueToAdd;
                                weekRow.nightHours += valueToAdd;
                            }
    
                        } else if(statementStartTime >= groupRules.nighttimeStart) {//  si la date de début de la déclaration est avant la fin de la nuit (de minuit à 5h du matin par exemple) ou après le début de la nuit (de 22h à minuit par exemple)
                                dayRow.nightHours += valueToAdd;
                                weekRow.nightHours += valueToAdd;
    
                        } else {    //Si la date de début est compris dans les heures de nuit ...
                            if((getMinuteNumberFromTime(statementStartTime) + ts.duration) > getMinuteNumberFromTime(groupRules.nighttimeStart)) {
                                //...Mais que la durée impose qu'il y ai une partie en heure de jour et une partie en heure de nuit
                                const tmpMinuteDayWOfWork = getMinuteNumberFromTime(groupRules.nighttimeStart) - getMinuteNumberFromTime(statementStartTime);
                                const tmpMinuteNightOfWork = getMinuteNumberFromTime(statementStartTime) + ts.duration - getMinuteNumberFromTime(groupRules.nighttimeStart);

                                dayRow.dayHours += Math.round((tmpMinuteDayWOfWork/60) * 10) / 10;
                                weekRow.dayHours += Math.round((tmpMinuteDayWOfWork/60) * 10) / 10;
                                dayRow.nightHours += Math.round((tmpMinuteNightOfWork/60) * 10) / 10;
                                weekRow.nightHours += Math.round((tmpMinuteNightOfWork/60) * 10) / 10;
                            } else {
                                dayRow.dayHours += valueToAdd;
                                weekRow.dayHours += valueToAdd;
                            }
                        }
                    }

                    //ADD FINNALY ALL DETAILS
                    sheetDetails.details.push({
                        date: ts.date,
                        dayHours: 0,
                        nightHours: 0,
                        overtime: 0,
                        roadHours: 0,
                        zone1A: 0,
                        zone1B: 0,
                        zone2: 0,
                        zone3: 0,
                        zone4: 0,
                        zone5: 0,
                        smallTrips: 0,
                        longTrips: 0
                    });

                } catch(e) {
                    alert("Une erreur est survenue, veuillez contacter l'administrateur : " + e);
                }
            }
        });

        //ADD DATA FOR DISCPLACEMENT STATEMENTS
        [{date: "2024-10-14", "userId": "aedc86e4-70fd-11ef-81d6-e0071bf394b0", distance: 15, noWayHome: 2}]?.forEach(ds => {

            if(ds.date >= startDateString && ds.date <= endDateString && selectedMembers.map(m => m.userId).includes(ds.userId)) {

                const member = groupDetails.members.filter(m => m.userId === ds.userId)[0];

                //ADD DYNAMICALLY DATA
                const sheetDetails = sheetsDetails.find(m => m.userId === member.userId);
                
                let dayRow = sheetDetails.days.find(d => getDate(d.date) === ds.date);
                let weekRow = sheetDetails.weeks.find(d => ds.date >= getDate(d.startDate) && ds.date <= getDate(d.endDate));

                if(ds.noWayHome === 1) {
                    dayRow.smallTrips++;
                    weekRow.smallTrips++;
                } else if (ds.noWayHome === 2) {
                    dayRow.longTrips++;
                    weekRow.longTrips++;
                }

                if(ds.distance <= 5) {
                    dayRow.zone1A++;
                    weekRow.zone1A++;
                } else if (ds.distance > 5 && ds.distance <= 10) {
                    dayRow.zone1B++;
                    weekRow.zone1B++;
                } else if (ds.distance > 10 && ds.distance <= 20) {
                    dayRow.zone2++;
                    weekRow.zone2++;
                } else if (ds.distance > 20 && ds.distance <= 30) {
                    dayRow.zone3++;
                    weekRow.zone3++;
                } else if (ds.distance > 30 && ds.distance <= 40) {
                    dayRow.zone4++;
                    weekRow.zone4++;
                } else if (ds.distance > 40) {
                    dayRow.zone5++;
                    weekRow.zone5++;
                }

                
                
            }
        });


        //MAP DATASTRUCTURE TO EXCEL
        const workbook = new ExcelJS.Workbook();
        sheetsDetails.forEach(sd => {
            const worksheet = workbook.addWorksheet(sd.name);

            const titles = ["Semaine par semaine", "Heures de jour", "Heures de nuit", "Heures de route", "Zone 1A (0-5km)", "Zone 1B (5-10km)", "Zone 2 (10-20km)", "Zone 3 (20-30km)", "Zone 4 (30-40km)", "Zone 5 (40-50km)", "Petit déplacements", "Grand déplacements"]

            worksheet.addRow([getBeautifulDate(startDate, {language: 0}, true) + " " + startDate.getFullYear() + " au " + getBeautifulDate(endDate, {language: 0}, true) + " " + startDate.getFullYear()]);
            
            const titleRow = worksheet.addRow(titles);

            titleRow.eachCell((cell, index) => {

                if(index === 2) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'd8e3be' } // Vert clair
                    };
                } else if (index === 3) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'A7CAEA' } // Bleu clair
                    };
                }
                else if (index === 4) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'e5b8b8' } // Rouge clair
                    };
                } else {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'EEEEEE' } // Gris clair
                    };
                }
            });
            
            sd.weeks?.forEach((w, indexY) => {
                const excelRow = worksheet.addRow(["Du " + getBeautifulDate(w.startDate, {language: 0}, false) + " au " + getBeautifulDate(w.endDate, {language: 0}, false), w.dayHours||"", w.nightHours||"", w.roadHours||"", w.zone1A||"", w.zone1B||"", w.zone2||"", w.zone3||"", w.zone4||"", w.zone5||"", w.smallTrips||"", w.longTrips||""])
            
                if (indexY % 2 === 1) {
                    excelRow.eachCell((cell) => {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'EEEEEE' } // Gris clair
                        };
                    });
                }

                excelRow.eachCell((cell, indexX) => {

                    

                    if(indexX === 2) {
                        // Si l'index de la ligne est impair, colorier la ligne en bleu clair
                        if (indexY % 2 === 1) {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'd8e3be' } // Vert clair
                            };
                        } else {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'ebf1df' } // Vert très clair
                            };
                        }
                    } else if (indexX === 3) {
                        if (indexY % 2 === 1) {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'A7CAEA' } // Bleu clair
                            };
                        } else {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'DAE9F7' } // Bleu très clair
                            };
                        }
                    }
                    else if (indexX === 4) {
                        if (indexY % 2 === 1) {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'e5b8b8' } // Rouge clair
                            };
                        } else {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'f1dcdb' } // Rouge très clair
                            };
                        }
                    }
                });
            });

            worksheet.addRow([""]);
            worksheet.addRow(["Jour par jour"]);
            sd.days?.forEach((w, indexY) => {
                const excelRow = worksheet.addRow([getBeautifulDate(w.date, {language: 0}, true), w.dayHours||"", w.nightHours||"", w.roadHours||"", w.zone1A||"", w.zone1B||"", w.zone2||"", w.zone3||"", w.zone4||"", w.zone5||"", w.smallTrips||"", w.longTrips||""])
            
                if (indexY % 2 === 1) {
                    excelRow.eachCell((cell) => {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'EEEEEE' } // Gris clair
                        };
                    });
                }

                excelRow.eachCell((cell, indexX) => {

                    

                    if(indexX === 2) {
                        // Si l'index de la ligne est impair, colorier la ligne en bleu clair
                        if (indexY % 2 === 1) {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'd8e3be' } // Vert clair
                            };
                        } else {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'ebf1df' } // Vert très clair
                            };
                        }
                    } else if (indexX === 3) {
                        if (indexY % 2 === 1) {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'A7CAEA' } // Bleu clair
                            };
                        } else {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'DAE9F7' } // Bleu très clair
                            };
                        }
                    }
                    else if (indexX === 4) {
                        if (indexY % 2 === 1) {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'e5b8b8' } // Rouge clair
                            };
                        } else {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'f1dcdb' } // Rouge très clair
                            };
                        }
                    }
                });
            });
            
            // STYLE
            worksheet.columns.forEach((column, index) => {
                index === 0 ? column.width = 30 : column.width = 17;
            });

            titles.forEach((title, index) => {
                worksheet.getCell(alphabet[index] + '2').border = {
                    top: {style:'medium', color: {argb:'000000'}}
                };

                worksheet.getCell(alphabet[index] + (3 + sd.weeksCount)).border = {
                    top: {style:'medium', color: {argb:'000000'}}
                };

                worksheet.getCell(alphabet[index] + (5 + sd.weeksCount)).border = {
                    top: {style:'medium', color: {argb:'000000'}}
                };

                worksheet.getCell(alphabet[index] + (5 + sd.weeksCount + sd.daysCount)).border = {
                    top: {style:'medium', color: {argb:'000000'}}
                };
            });

            
        });

        // Générer le fichier Excel et le télécharger
        try {
            const buffer = await workbook.xlsx.writeBuffer();
            const blobWorkbook = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            saveAs(blobWorkbook, 'Déclarations ' + getDate(startDate) + ' - ' + getDate(endDate) + '.xlsx');
        } catch (error) {
            console.error("Erreur lors de la création du fichier Excel : ", error);
        }
    };

    return (
        <button style={{height: 30, alignSelf: 'center', marginLeft: 'auto', marginRight: 30, borderRadius: 8, color: 'white', backgroundColor: '#F6B60D'}} onClick={exportToExcel}>Télécharger en Excel</button>
    );
};

export default ExportExcel;