import { useState } from "react";

import { getUserToken } from "../../api/keyhouseApi/AuthenticationApi";

function Login(props) {

    let [response, setErreur] = useState("");
    let [mail, setMail] = useState("");
    let [password, setPassword] = useState("");

    async function getToken() {

        let token = await getUserToken(mail, password);

        if(token && token.accessToken) {
            props.setAuthenticationToken(token.accessToken);
        } else {
            setErreur("Erreur dans vos identifiants");
        }
    }

    return (
        <div>
            <div>
                <h1 className="h3 mb-3 font-weight-normal">Connexion</h1>
                <p>{response}</p>
                <label className="sr-only" htmlFor="inputEmail">Email address</label>
                <input style={{width: 400}} value={mail} onChange={(e) => setMail(e.target.value)} className="form-control" type="email" name="mail" id="inputEmail" placeholder="Email address" required="required" autofocus="autofocus" />
                <label className="sr-only" htmlFor="inputPassword">Password</label>
                <input value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" type="password" name="password" id="inputPassword" placeholder="Password" required="required" />
                <button onClick={() => getToken()} className="btn btn-lg btn-primary btn-block buttonClass" type="submit">Se connecter</button>
            </div>
        </div>
      );
}

export default Login;


