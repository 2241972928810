import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Support from './Components/Support';
import About from './Components/About';
import BackOffice from './Components/Admin/BackOffice';
import Creator from './Components/Creator/Creator';
import BecomeCreator from './Components/BecomeCreator';
import ConditionsGeneralesUtilisation from './Components/ConditionsGeneralesUtilisation';
import CharteDeDonnees from './Components/CharteDeDonnees';
import MentionsLegales from './Components/MentionsLegales';
import Home from './Components/Home';

// CLOCK IN EASE
import Dashboard from './Components/ClockInEase/Dashboard';

function App() {
  return (
    <BrowserRouter basename='/'>
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/support" element={<Support/>} />
            <Route path="/backoffice" element={<BackOffice/>} />
            <Route path="/creator-dashboard" element={<Creator/>} />
            <Route path="/creator" element={<BecomeCreator/>} />

            <Route path="/conditions-generales-utilisation" element={<ConditionsGeneralesUtilisation/>} />
            <Route path="/mentions-legales" element={<MentionsLegales/>} />
            <Route path="/charte-de-donnees" element={<CharteDeDonnees/>} />
        </Routes>

        <Routes>
            <Route path="/clockinease" element={<Dashboard/>} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
