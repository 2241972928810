import Layout from "./Layout";

function About(props) {

    return (
        <Layout>
            <div className="center">
                <p style={{margin: 50}}>
                    Nous sommes une équipe passionnée d'étudiants ayant créé Talk&Poke, un projet né de notre curiosité et de notre désir d'explorer de nouvelles façons de connecter les gens. Initialement conçu comme un projet de cours, Talk&Poke est devenu une plateforme innovante où les passions et les affinités sont au cœur des rencontres. Notre objectif est de vous offrir une expérience unique, où chaque interaction est basée sur ce que vous aimez. Rejoignez-nous dans cette aventure et découvrez des connexions authentiques à travers vos centres d'intérêt. Merci de faire partie de notre histoire !
                </p>
            </div>
        </Layout>
      );
}

export default About;

