import React from 'react';

import { AfficheImageMapper } from "../../Mapper/AfficheImageMapper";
import BackArrow from './BackArrow';
import ChangeImagePng from '../../resources/ChangeImage.png';

function PosterDetails(props) {
    
    if(!props.show) return null;

    return (
        <div>
            <BackArrow backCommand={() => props.setSelectedPoster(undefined)}/>
            
            <div className="wrap horizontalcenter" style={{marginBottom: 0, marginTop: 0}}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div style={{marginRight: 50}}>
                        <img className="giantPoster" style={{margin: 0}} src={props.poster.isLocal ? AfficheImageMapper[props.poster.code] : {uri: props.poster.url}} alt={props.poster.posterTitle}/>

                        <div style={{position: 'relative', top: -380, left: 240, backgroundColor: 'orange', borderRadius: 100, width: 60, height: 60}}>
                            <img style={{height: 30, width: 30, margin: 15}} src={ChangeImagePng} alt={props.poster.posterTitle}/>
                        </div>

                        <p style={{fontSize: 25, paddingTop: 0}}>{props.poster.posterTitle}</p>
                    </div>

                    <div>
                        <p style={{fontSize: 25, marginBottom: 0, paddingBottom: 0}}>Description</p>
                        <p style={{fontSize: 20}}>{props.poster.description}</p>
                    </div>
                </div>
            </div>

            <div>
                <p style={{fontSize: 25, marginBottom: 0, paddingBottom: 0}}>Messages</p>

            </div>
        </div>

      );
}

export default PosterDetails;

