 
function UserProfilPicture(props) {

    let size = 100;
    size = props.size;

    var Picture = () => {
        if(!props.picture)
            return null;

        function onErrorImage(e) {
            e.target.parentNode.style.display="none";
        }

        return(
            <div>
                <div>
                    <img onError={onErrorImage} style={{width: size, height: size, borderRadius: 100, objectFit: 'cover'}} src={props.picture} alt="user"/>
                </div>
            </div>
        )
    }

    // var Initial = () => {

    //     if(!props.pseudo)
    //         return null;

    //     return(
    //         <p style={{color: "white", textAlign: 'center', fontSize: Number.isInteger(props.size) ? props.size * 0.4 : props.fontSize}}>{props.pseudo[0].toUpperCase()}{props.pseudo[props.pseudo?.length - 1].toUpperCase()}</p>
    //     )
    // }

    return(
        <div className="cercle" 
             style={{  height: size, 
                        width: size, 
                        backgroundColor: props.backgroundColor ? props.backgroundColor : '#eee'}}>
            {/* <Initial/> */}
            <Picture/>
        </div>
    )
}
export default UserProfilPicture;